<template>
    <BlockContainer
        :background-color="data.backgroundColor"
        :bottom-angle="data.bottomAngle"
        :angle-background-color="data.nextBackgroundColor"
        :id="'credit-application-form-' + slugify(data.title)"
    >
        <div class="min-h-96 mx-auto mb-24 mt-16 max-w-3xl">
            <div v-if="!success">
                <h3 class="mb-6 text-center">
                    {{ data.requirementsTitle }}
                </h3>
                <div class="whitespace-pre-wrap">{{ data.requirements }}</div>
            </div>
            <h3 class="mb-6 mt-14 text-center">{{ data.title }}</h3>
            <ValidatedForm
                v-if="!success"
                ref="formRef"
                :form-errors="formErrors"
                :show-required-fields-message="true"
            >
                <TextInput
                    v-model="company"
                    name="company_name"
                    type="text"
                    label="Company *"
                    :submitting="submitting"
                    :form-errors="formErrors"
                    container-class="w-full mb-4"
                    input-class="floating-label-white"
                    required
                />
                <TextInput
                    v-model="name"
                    name="name"
                    type="text"
                    label="Name *"
                    :submitting="submitting"
                    :form-errors="formErrors"
                    container-class="w-full mb-4"
                    input-class="floating-label-white"
                    required
                />
                <div class="flex flex-row gap-4">
                    <TextInput
                        v-model="email"
                        name="email"
                        type="text"
                        label="Email Address *"
                        :submitting="submitting"
                        :form-errors="formErrors"
                        container-class="w-1/2 mb-4"
                        input-class="floating-label-white"
                        required
                    />
                    <TextInput
                        v-model="phone"
                        name="phone"
                        type="tel"
                        label="Phone Number *"
                        :submitting="submitting"
                        :form-errors="formErrors"
                        container-class="w-1/2 mb-4"
                        input-class="floating-label-white"
                        required
                    />
                </div>
                <TextareaInput
                    v-model="comments"
                    name="comments"
                    label="Comments *"
                    :submitting="submitting"
                    :form-errors="formErrors"
                    container-class="w-full text"
                    input-class="floating-label-white"
                    required
                />
                <input type="hidden" name="page" value="credit-application" />
                <div
                    v-if="recaptchaEnabled"
                    class="g-recaptcha mt-4"
                    :data-sitekey="recaptchaSiteKey"
                ></div>
            </ValidatedForm>
            <LoadingButton
                v-if="!success"
                type="submit"
                class="btn-primary mt-10 w-full"
                :loading="submitting"
                @click="validateAndSubmit()"
            >
                Submit
            </LoadingButton>
            <div class="text-grind">
                <Alert v-if="success" type="success" :dismissable="false">
                    Your request has been submitted! We will get in touch within
                    3 to 5 business days.
                </Alert>
            </div>
        </div>
    </BlockContainer>
</template>

<script setup lang="ts">
import CreditApplicationFormType from '~/types/CreditApplicationFormType';
import BlockContainer from '~/components/BlockContainer.vue';
import TextInput from '~/components/forms/TextInput.vue';
import TextareaInput from '~/components/forms/TextareaInput.vue';
import ValidatedForm from '~/components/forms/ValidatedForm.vue';
import { useSegmentEvent } from '~/composables/useSegment';
import { sendDataLayerFormEvent } from '~/composables/useDataLayer';

defineProps<{
    data: CreditApplicationFormType;
}>();

const { recaptchaEnabled, recaptchaSiteKey } = useRuntimeConfig().public;

const formRef = ref<InstanceType<typeof ValidatedForm> | null>(null);

const state = reactive({
    company: '',
    name: '',
    email: '',
    phone: '',
    comments: '',
    submitting: false,
    success: false,
    formErrors: {},
});

const {
    company,
    name,
    email,
    phone,
    comments,
    submitting,
    success,
    formErrors,
} = toRefs(state);

const validateAndSubmit = async () => {
    if (formRef.value?.validate()) {
        state.submitting = true;

        const recaptchaToken =
            recaptchaEnabled && window.grecaptcha
                ? window.grecaptcha.getResponse()
                : '';

        if (recaptchaEnabled && recaptchaToken == '') {
            state.success = false;
            state.formErrors = [
                'You must complete the reCAPTCHA in order to submit this form.',
            ];
        } else {
            const formData = formRef.value?.getFormData();
            formData?.set('recaptchaToken', recaptchaToken);

            await useAuth()
                .apiRequest('POST', 'credit-application-ajax', formData!)
                .then((response) => {
                    state.formErrors = {};
                    state.success = true;
                    state.company = '';
                    state.name = '';
                    state.email = '';
                    state.phone = '';
                    state.comments = '';

                    sendDataLayerFormEvent('form_submit', 'Credit Application', 'credit-application-form');

                    const segmentEvent = useSegmentEvent(
                        'Credit Application Form Submit',
                        'Credit Application',
                        'Credit Application Form Submit',
                    );

                    useHead(() => ({
                        script: segmentEvent,
                    }));
                })
                .catch((error) => {
                    state.formErrors = getFormErrors(error.data);
                })
                .finally(() => {
                    if (recaptchaEnabled && window.grecaptcha) {
                        window.grecaptcha.reset();
                    }

                    state.submitting = false;
                });
        }
    }
};

useHead({
    script: [
        {
            src: 'https://www.google.com/recaptcha/api.js',
            async: true,
            defer: true,
        },
    ],
});
</script>
